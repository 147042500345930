/**
 * Refresh an expired ID token by redirecting the user to Flash UI.
 * Copied from https://github.com/EnergySage/rooftop-quote-display/blob/main/middleware/flash-refresh.ts
 * In future, this might be extracted to a shared plugin
 */
import { Context } from '@nuxt/types';
import { jwtDecode } from 'jwt-decode';

export enum TokenStatus {
    MISSING,
    INVALID,
    EXPIRED,
    VALID,
}

export function checkFlashIdToken(idToken: string): TokenStatus {
    if (!idToken) {
        // ID token cookie not set, user is not logged in
        return TokenStatus.MISSING;
    }

    let idTokenClaims;
    try {
        // TODO: Do proper token validation?
        // It adds overhead, but might avoid poor UX of logout() in FlashExpirationMiddleware.
        // See https://github.com/auth0/node-jsonwebtoken and https://github.com/auth0/node-jwks-rsa,
        // or https://github.com/awslabs/aws-jwt-verify.
        idTokenClaims = jwtDecode<{ exp?: number }>(idToken);
    } catch {
        // ID token is set, but can't be decoded
        return TokenStatus.INVALID;
    }
    if (!idTokenClaims.exp) {
        return TokenStatus.INVALID;
    }

    // Set expiry 1 hour earlier to allow for gap between this check and when the token is used
    const idTokenExpiry = idTokenClaims.exp - 60 * 60;
    if (idTokenExpiry * 1000 < Date.now()) {
        return TokenStatus.EXPIRED;
    }

    // HACK: Allow refreshing with an otherwise valid token (e.g. for testing). In DevTools:
    // localStorage.setItem('refreshFlash', 1); location.reload()
    // Not using a query param because of the complexity of removing it before setting next=.
    /* c8 ignore start */
    if (localStorage.getItem('refreshFlash')) {
        localStorage.removeItem('refreshFlash');
        return TokenStatus.EXPIRED;
    }
    /* c8 ignore end */

    return TokenStatus.VALID;
}

export default ({ $cookies, redirect }: Context) => {
    const idToken = $cookies.get('flash.id_token');
    if (process.env.NODE_ENV !== 'development' && checkFlashIdToken(idToken) !== TokenStatus.VALID) {
        const loginUrl = `${process.env.ACCOUNTS_DOMAIN}/login/`;
        const refreshUrl = `${loginUrl}?refresh=1&next=${encodeURIComponent(window.location.href)}`;
        redirect(refreshUrl);
    }
};
